import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query MdxExports {
      allMdx {
        nodes {
          timeToRead
          id
          slug
          tableOfContents
          frontmatter {
            author
            last
            title
          }
          excerpt
        }
      }
    }
  `)
  console.log(data)
  return (
    <Layout>
      <SEO title="Home" />
      {data.allMdx.nodes.map(post => (
        <div>
          <h2
            style={{
              marginBottom: "0.3em",
            }}
          >
            <Link
              to={`/${post.slug}`}
              style={{
                color: `#333`,
                textDecoration: `none`,
                fontSize: "0.7em",
              }}
            >
              {post.frontmatter.title}
            </Link>
          </h2>
          <p>{post.excerpt}</p>
        </div>
      ))}
    </Layout>
  )
}

export default IndexPage
